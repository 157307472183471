/* Container for the cards */
.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin: 10px;
  }
  
 
  @media (max-width: 1024px) {
    .cards-container {
      grid-template-columns: repeat(3, 1fr); /* 3 cards for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .cards-container {
      grid-template-columns: repeat(2, 1fr); /* 2 cards for mobile landscape */
    }
  }
  
  @media (max-width: 500px) {
    .cards-container {
      grid-template-columns: repeat(1, 1fr); /* 1 card for small mobile */
    }
  }
  